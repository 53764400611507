

@import "bourbon/bourbon";

$fluid: .7,.3,0,1;
$fluidless: .5,.5,0,1;
$smootheaseout: .62,.02,.34,1;
$intenseeaseout: 1,0,0,1;
$lessintenseperfect: .55,0,0.45,1;
$suckitin: .38,-0.32,.89,.34;
$smoothswing: 0.4, 0.3, 0.1, .4;
$gentleeasein: .5,0,.57,1;
$lineareaseout: .45,.65,.64,1;
$sweepinginout: .52,.01,.16,1;
$lineareasein: .52,.7,.41,1;
$gentleout: .11,.62,.3,.98;
$sweetout: .19,1,.22,1;
$bouncey: .1,.55,.29,1.44;
$bgwhite: rgb(242,242,242);
$nurture-black: rgb(39,40,46);

html:not(.backgroundblendmode) {

	.home .wrapper {
		background-color: #7fbfd2;
	}

// html.backgroundblendmode {
	.blend-lighten, .blend-screen {
		background-color: transparent;
	}
	.full-screen-transition {
		background-color: transparent;
	}
	.home-video-mask {
		> *:not(.full-screen-transition) {
			display: none;
		}
	}
	div {
		mix-blend-mode: initial !important;
	}
	.home-bg, .type-wrap {
		z-index: 11;
	}
	.home-front-experience-wrap {
		z-index: 12;
	}
	.home-front-type-content {
		z-index: 13;
	}
	.home-bg {
		
		@include transition(0.85s cubic-bezier($sweepinginout));
		@include transform-origin(0 0);
		left: 56%;
		width: 44%;
		.vid-bg {
			display: none;
		}
		&[data-home-state="video-full"] {
			@include transform(translateY(100%));
		}
		&[data-active-case="0"] li.home-front-bg {
			@include transform(translateX(-56.5vw) scaleX(2.3));
		}
	}

	.about-imagery {

		.blend-lighten {
			
			&::before {
				content: '';
				position: absolute;
				top: 0;
				height: 100%;
				width: 60%;
				left: 40%;
				background-color: $bgwhite;
				@include transition(0.7s cubic-bezier($sweepinginout) 0.4s);
			}
			.full-screen-transition {
				background-color: transparent;
			}
			h1 {
				color: $bgwhite;
			}

		}

		.js-about-video {
			@include transition(0.85s cubic-bezier($sweepinginout));
			overflow: hidden;
			&[data-active="off"] { 
				&::before {
					@include transform(translateY(40%));
				}
				@include transform(translateY(100%));
			}
		}

	}

	.blog-thumbs li .blog-thumb-wrap .color-overlay {
		display: none;
	}

	.article .article-head .color-overlay { 
		background: rgba(255,255,255,0.4) !important;
	}

}


	
